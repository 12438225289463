import { createApp } from 'vue';
import { store } from './store';
import './assets/tailwind.css';
import App from './App.vue';
import Amplify, { Auth } from 'aws-amplify';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import router from './router';

import jsPlumb from 'jsplumb';
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
import 'flowbite';

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_rWGeMi1Nf',
    userPoolWebClientId: '50ij8v0vlnpp6l1e26tslhtgiq',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
  },
  API: {
    endpoints: [
      {
        name: 'APIGateway',
        endpoint: process.env.VUE_APP_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: 'IoTAPIGateway',
        endpoint: process.env.VUE_APP_IOT_API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapIcon);
app.mount('#app');
app.config.globalProperties.$jsPlumb = jsPlumb.jsPlumb;
app.component('vLoading', Loading);
