<template>
  <amplify-authenticator>
    <div id="wrap">
      <div class="menuBtn" @click="menuHandler">
        <span>menu</span>
      </div>
      <template v-if="authState === 'signedin' && user">
        <div id="nav" :class="{ active: isActive }">
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['UserData'] &&
                userPermissionsData['UserData'].includes('user_info') > 0
            "
            to="/"
            >User Data</router-link
          >
          <router-link to="/Dashboard">Dashboard</router-link>
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['MQTTMessage'] &&
                userPermissionsData['MQTTMessage'].includes('log_mqtt_message')
            "
            to="/mqtt_message"
            >MQTT Payload Monitor</router-link
          >
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['MQTTMonitor'] &&
                userPermissionsData['MQTTMonitor'].includes('log_mqtt_status')
            "
            to="/mqtt_monitor"
            >MQTT Action Monitor</router-link
          >
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['APILogs'] &&
                userPermissionsData['APILogs'].includes('log_api')
            "
            to="/api_logs"
            >API Logs</router-link
          >
          <!-- <router-link to="/user_data">User Data</router-link> -->
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['Statistics'] &&
                userPermissionsData['Statistics'].length > 0
            "
            to="/statis"
            >Statistics</router-link
          >
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['MessageTemplate'] &&
                userPermissionsData['MessageTemplate'].includes('msg_tpl_read')
            "
            to="/message_template"
            >Message Template</router-link
          >
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['FirmwareManagement'] &&
                userPermissionsData['FirmwareManagement'].includes('fw_release')
            "
            to="/firmware_management/1"
            >Firmware Management</router-link
          >
          <router-link to="/alarm">Alarms</router-link>
          <!-- <router-link to="/billboard">Billboard</router-link> -->
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['Billboard'] &&
                userPermissionsData['Billboard'].length > 0
            "
            :to="'/billboard/' + BillboardPath"
            >Billboard</router-link
          >
          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['AppInfo'] &&
                (userPermissionsData['AppInfo'].includes('app_info') ||
                  userPermissionsData['AppInfo'].includes('app_url'))
            "
            to="/app"
            >App Management</router-link
          >

          <router-link
            v-if="
              userPermissionsData &&
                userPermissionsData['Settings'] &&
                userPermissionsData['Settings'].includes('settings')
            "
            to="/settings"
            >Settings</router-link
          >
          <amplify-sign-out button-text="Sign out"></amplify-sign-out>
        </div>
        <div id="content">
          <router-view />
        </div>
      </template>
    </div>
  </amplify-authenticator>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AuthStateApp',
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      // console.log(authState);
      this.authState = authState;
      this.user = authData;
      if (authState === 'signedin') {
        this.getUserPermissions();
      }
    });
  },
  data() {
    return {
      isActive: false,
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
    };
  },
  computed: {
    ...mapGetters({
      userPermissionsData: 'getUserPermissionsData',
    }),
    BillboardPath() {
      let path = '';
      if (this.userPermissionsData?.Billboard?.length > 0) {
        path = this.userPermissionsData.Billboard[0].replace('billboard_', '');
      }
      return path;
    },
  },
  methods: {
    ...mapActions({
      getUserPermissions: 'getUserPermissions',
    }),
    menuHandler() {
      this.isActive = !this.isActive;
    },
  },
  beforeUnmount() {
    this.unsubscribeAuth();
  },
};
</script>

<style>
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

#wrap {
  display: flex;
  position: relative;
}
#content {
  width: calc(100% - 250px);
  /* margin: 0 auto; */
  min-height: 100vh;
  padding: 30px;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */
#nav {
  width: 250px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-right: 3px solid #d8dddb;
  position: relative;
  left: 0;
  transition: all 0.3s;
}
#navbar {
  width: 250px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  transition: all 0.3s;
}

#nav a,
#navbar a {
  font-weight: bold;
  color: #2c3e50;
  padding: 10px;
  min-height: 50px;
  text-decoration: none;
  transition: transform 0.3s;
}

#nav a:hover,
#navbar a:hover {
  color: rgb(145, 145, 145);
  transform: translateX(10px);
}

#nav a.router-link-exact-active,
#nav a.router-link-active,
#navbar a.router-link-exact-active,
#navbar a.router-link-active {
  color: #42b983;
}

@media screen and (max-width: 1200px) {
  #content {
    width: 100%;
    padding: 0;
  }
  #nav {
    left: -100%;
    top: 0;
    width: 30vw;
    height: 100vh;
    position: fixed;
    z-index: 9;
    min-width: 200px;
    background-color: #fff;
  }
  .menuBtn {
    cursor: pointer;
    opacity: 1 !important;
  }
}

#nav.active {
  left: 0 !important;
}

.menuBtn {
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
}
.menuBtn span {
  opacity: 0;
}
.menuBtn::before {
  content: '';
  position: absolute;
  width: 36px;
  height: 2px;
  left: 2px;
  background-color: #e66d28;
  top: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 0 10px 0 #e66d28, 0 -10px 0 #e66d28;
}

.table {
  align-self: center;
  border-collapse: collapse;
  /* margin: 0 auto; */
}
.table th {
  border: 1px solid;
  font-weight: bold;
}
.table td {
  border: 1px solid;
  padding: 5px;
}
pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.btn {
  margin: 0.5rem;
  cursor: pointer;
  min-width: 80px;
  padding: 0.5rem;
  border-width: 0;
  display: inline-block;
  font-stretch: normal;
  font-weight: 700;
}
.btn-orange {
  background-color: #ff9900;
  color: white;
}
.btn-orange:hover {
  background-color: #eb5f07;
}
.cancel-button {
  background: transparent;
}
.cancel-button:hover {
  background: #fafafa;
  color: #16191f;
}
.dk-label {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 10px;
}
</style>
