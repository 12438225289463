import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'newuserdata',
    component: () =>
      import(/* webpackChunkName: "newuserData" */ '../views/NewUserData.vue'),
  },
  {
    path: '/qa_mode/:email',
    component: () =>
      import(/* webpackChunkName: "debug_mode" */ '../views/DebugMode.vue'),
  },
  {
    path: '/DashBoard',
    name: 'DashBoard',
    component: () =>
      import(/* webpackChunkName: "DashBoard" */ '../views/DashBoard.vue'),
  },
  {
    path: '/mqtt_message',
    name: 'MQTTMessage',
    component: () =>
      import(/* webpackChunkName: "mqtt_message" */ '../views/MQTTMessage.vue'),
  },
  {
    path: '/mqtt_monitor',
    name: 'MQTTMonitor',
    component: () =>
      import(/* webpackChunkName: "mqtt_monitor" */ '../views/MQTTMonitor.vue'),
  },
  {
    path: '/api_logs',
    name: 'APILogs',
    component: () =>
      import(/* webpackChunkName: "api_logs" */ '../views/APILogs.vue'),
  },
  // {
  //   path: '/user_data',
  //   name: 'UserData',
  //   component: () =>
  //     import(/* webpackChunkName: "user_data" */ '../views/UserData.vue'),
  //   children: [
  //     {
  //       path: 'device',
  //       component: () =>
  //         import(/* webpackChunkName: "device" */ '../views/Device.vue'),
  //     },
  //     {
  //       path: 'mobile',
  //       component: () =>
  //         import(/* webpackChunkName: "mobile" */ '../views/Mobile.vue'),
  //     },
  //     {
  //       path: 'subuser',
  //       component: () =>
  //         import(/* webpackChunkName: "subuser" */ '../views/Subuser.vue'),
  //     },
  //     {
  //       path: 'region',
  //       component: () =>
  //         import(/* webpackChunkName: "region" */ '../views/Region.vue'),
  //     },
  //     {
  //       path: 'group',
  //       component: () =>
  //         import(/* webpackChunkName: "group" */ '../views/Group.vue'),
  //     },
  //     {
  //       path: 'qa_mode',
  //       component: () =>
  //         import(/* webpackChunkName: "debug_mode" */ '../views/DebugMode.vue'),
  //     },
  //   ],
  // },
  {
    path: '/statis',
    name: 'Statistics',
    component: () =>
      import(/* webpackChunkName: "statis" */ '../views/Statis.vue'),
    redirect: '/statis/statis_reg_user',
    children: [
      {
        path: '/statis/statis_reg_user',
        component: () =>
          import(
            /* webpackChunkName: "statis_reg_user" */ '../views/StatisRegUser.vue'
          ),
      },
      {
        path: '/statis/statis_reg_device',
        component: () =>
          import(
            /* webpackChunkName: "statis_reg_device" */ '../views/StatisRegDevice.vue'
          ),
      },
      {
        path: '/statis/statis_fw_version',
        component: () =>
          import(
            /* webpackChunkName: "statis_fw_version" */ '../views/StatisFwVersion.vue'
          ),
      },
      {
        path: '/statis/statis_act_device',
        component: () =>
          import(
            /* webpackChunkName: "statis_act_device" */ '../views/StatisActDevice.vue'
          ),
      },
      {
        path: '/statis/statis_act_user',
        component: () =>
          import(
            /* webpackChunkName: "statis_act_user" */ '../views/StatisActUser.vue'
          ),
      },
      {
        path: 'past_act_device',
        component: () =>
          import(
            /* webpackChunkName: "past_act_device" */ '../views/StatisPastActDevice.vue'
          ),
      },
      {
        path: 'past_act_user',
        component: () =>
          import(
            /* webpackChunkName: "past_act_user" */ '../views/StatisPastActUser.vue'
          ),
      },
    ],
  },
  {
    path: '/message_template',
    name: 'MessageTemplate',
    component: () =>
      import(
        /* webpackChunkName: "message_template" */ '../views/MessageTemplate.vue'
      ),
  },
  {
    path: '/firmware_management',
    name: 'firmwareManagement',
    component: () =>
      import(/* webpackChunkName: "Firmware" */ '../views/Firmware.vue'),
    redirect: '/firmware_management/1',
    children: [
      {
        path: '/firmware_management/:firmwareId',
        name: 'FirmwareManagement',
        component: () =>
          import(
            /* webpackChunkName: "firmware_management" */ '../views/FirmwareManagement.vue'
          ),
      },
      {
        path: '/firmware_management/byUser',
        name: 'FirmwareManagementByUser',
        component: () =>
          import(
            /* webpackChunkName: "firmware_management_by_user" */ '../views/FirmwareManagementByUser.vue'
          ),
      },
    ],
  },
  {
    path: '/alarm',
    name: 'AlarmSystem',
    component: () =>
      import(/* webpackChunkName: "alarm" */ '../views/AlarmSystem.vue'),
  },
  {
    path: '/app',
    name: 'AppRoute',
    component: () =>
      import(/* webpackChunkName: "AppRoute" */ '../views/AppRoute.vue'),
    // redirect: '/app/info',
    children: [
      {
        path: 'info',
        name: 'AppInfo',
        component: () =>
          import(/* webpackChunkName: "AppInfo" */ '../views/AppInfo.vue'),
      },
      {
        path: 'url',
        name: 'AppUrl',
        component: () =>
          import(/* webpackChunkName: "AppUrl" */ '../views/AppUrl.vue'),
      },
    ],
  },

  {
    path: '/settings',
    name: 'Settings',
    component: () =>
      import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    redirect: '/settings/users',
    children: [
      {
        path: '/settings/users',
        component: () =>
          import(
            /* webpackChunkName: "settings_users" */ '../views/SettingsUsers.vue'
          ),
      },
      {
        path: '/settings/users/:email',
        component: () =>
          import(
            /* webpackChunkName: "settings_user" */ '../views/SettingsUser.vue'
          ),
      },
      {
        path: '/settings/users/:email/group',
        component: () =>
          import(
            /* webpackChunkName: "settings_user_group" */ '../views/SettingsUserGroup.vue'
          ),
      },
      {
        path: '/settings/groups',
        component: () =>
          import(
            /* webpackChunkName: "settings_groups" */ '../views/SettingsGroups.vue'
          ),
      },
      {
        path: '/settings/groups/form',
        component: () =>
          import(
            /* webpackChunkName: "settings_groups_form" */ '../views/SettingsGroupsForm.vue'
          ),
      },
      {
        path: '/settings/groups/:groupName',
        component: () =>
          import(
            /* webpackChunkName: "settings_group" */ '../views/SettingsGroup.vue'
          ),
      },
      {
        path: '/settings/groups/:groupName/user',
        component: () =>
          import(
            /* webpackChunkName: "settings_group_user" */ '../views/SettingsGroupUser.vue'
          ),
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
  },
  {
    path: '/billboard',
    name: 'Billboard',
    component: () =>
      import(/* webpackChunkName: "billboard" */ '../views/Billboard.vue'),
    // redirect: '/billboard/activity',
    children: [
      {
        path: '/billboard/:category',
        component: () =>
          import(
            /* webpackChunkName: "billboard_by_category" */ '../views/BillboardByCategory.vue'
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
