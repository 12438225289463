import { createStore } from 'vuex';
import { API } from 'aws-amplify';
import moment from 'moment';
import router from '../router';
import enums from '@/store/enums';

const types = {
  SET_LOADING: 'SET_LOADING',
  SET_LAST_PROMISE: 'SET_LAST_PROMISE',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_STATIS_REG_USER_DATA: 'SET_STATIS_REG_USER_DATA',
  SET_STATIS_REG_USER_DATA_INIT: 'SET_STATIS_REG_USER_DATA_INIT',
  SET_STATIS_REG_DEVICE_DATA: 'SET_STATIS_REG_DEVICE_DATA',
  SET_STATIS_REG_DEVICE_DATA_INIT: 'SET_STATIS_REG_DEVICE_DATA_INIT',
  SET_STATIS_FW_VERSION_DATA: 'SET_STATIS_FW_VERSION_DATA',
  SET_STATIS_FW_VERSION_DATA_INIT: 'SET_STATIS_FW_VERSION_DATA_INIT',
  SET_STATIS_ACT_DEVICE_DATA: 'SET_STATIS_ACT_DEVICE_DATA',
  SET_STATIS_ACT_DEVICE_DATA_INIT: 'SET_STATIS_ACT_DEVICE_DATA_INIT',
  SET_STATIS_ACT_USER_DATA: 'SET_STATIS_ACT_USER_DATA',
  SET_STATIS_ACT_USER_DATA_INIT: 'SET_STATIS_ACT_USER_DATA_INIT',
  SET_STATIS_PAST_ACT_USER_DATA: 'SET_STATIS_PAST_ACT_USER_DATA',
  SET_STATIS_PAST_ACT_USER_DATA_INIT: 'SET_STATIS_PAST_ACT_USER_DATA_INIT',
  SET_STATIS_PAST_ACT_DEVICE_DATA: 'SET_STATIS_PAST_ACT_DEVICE_DATA',
  SET_STATIS_PAST_ACT_DEVICE_DATA_INIT: 'SET_STATIS_PAST_ACT_DEVICE_DATA_INIT',
  SET_DEVICE_DATA: 'SET_DEVICE_DATA',
  SET_DEVICE_SHADOW_DATA: 'SET_DEVICE_SHADOW_DATA',
  SET_MOBILE_DATA: 'SET_MOBILE_DATA',
  SET_SUBUSER_DATA: 'SET_SUBUSER_DATA',
  SET_REGION_DATA: 'SET_REGION_DATA',
  SET_GROUP_DATA: 'SET_GROUP_DATA',
  SET_MESSAGE_TEMPLATE_DATA: 'SET_MESSAGE_TEMPLATE_DATA',
  SET_FIRMWARE_IDS_DATA: 'SET_FIRMWARE_IDS_DATA',
  SET_FIRMWARE_OBJECT_LIST_DATA: 'SET_FIRMWARE_OBJECT_LIST_DATA',
  SET_FIRMWARE_PRESIGNEDURL_DATA: 'SET_FIRMWARE_PRESIGNEDURL_DATA',
  SET_MQTT_CREDENTIALS_DATA: 'SET_MQTT_CREDENTIALS_DATA',
  SET_USER_PERMISSIONS_DATA: 'SET_USER_PERMISSION_DATA',
  SET_PERMISSIONS_DATA: 'SET_PERMISSIONS_DATA',
  SET_CONSOLE_USERS_DATA: 'SET_CONSOLE_USERS_DATA',
  SET_CONSOLE_USER_DATA: 'SET_CONSOLE_USER_DATA',
  SET_CONSOLE_USER_GROUPS_DATA: 'SET_CONSOLE_USER_GROUPS_DATA',
  SET_CONSOLE_GROUPS_DATA: 'SET_CONSOLE_GROUPS_DATA',
  SET_CONSOLE_GROUP_USERS_DATA: 'SET_CONSOLE_GROUP_USERS_DATA',
  SET_CONSOLE_GROUP_PERMISSIONS_DATA: 'SET_CONSOLE_GROUP_PERMISSIONS_DATA',
  SET_RULE_ENGINE_SQL_QA_DATA: 'SET_RULE_ENGINE_SQL_QA_DATA',
  SET_CONNECTION_STATUS_IMAGE: 'SET_CONNECTION_STATUS_IMAGE',
  SET_PUBLISH_RATE_IMAGE: 'SET_PUBLISH_RATE_IMAGE',
  SET_MQTT_ACTION_RATE_IMAGE: 'SET_MQTT_ACTION_RATE_IMAGE',
  SET_API_REQUEST_RATE_IMAGE: 'SET_API_REQUEST_RATE_IMAGE',
  SET_IOTCORE_PUBLISH_STATUS_IMAGE: 'SET_IOTCORE_PUBLISH_STATUS_IMAGE',
  SET_AlARM: 'SET_AlARM',
  SET_BILLBOARD_DATA: 'SET_BILLBOARD_DATA',
  ADD_BILLBOARD_DATA: 'ADD_BILLBOARD_DATA',
  UPDATE_BILLBOARD_DATA: 'UPDATE_BILLBOARD_DATA',
  SET_HOST_USER: 'SET_HOST_USER',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  SET_APP_INFO: 'SET_APP_INFO',
  UPDATE_APP_INFO: 'UPDATE_APP_INFO',
  SET_APP_URL: 'SET_APP_URL',
  UPDATE_APP_URL: 'UPDATE_APP_URL',
};
export const store = createStore({
  state: {
    isLoading: false,
    lastPromise: null,
    userData: null,
    statisRegUserData: [],
    statisRegDeviceData: [],
    statisFwVersionData: [],
    statisActDeviceData: [],
    statisActUserData: [],
    statisPastActDeviceData: [],
    statisPastActUserData: [],
    deviceData: [],
    deviceShadowData: [],
    mobileData: null,
    subuserData: [],
    regionData: [],
    groupData: [],
    messageTemplateData: [],
    firmwareIdsData: [],
    firmwareObjectListData: [],
    firmwarePresignedurlData: null,
    mqttCredentialsData: null,
    userPermissionsData: null,
    permissionsData: [],
    consoleUsersData: [],
    consoleUserData: null,
    consoleUserGroupsData: [],
    consoleGroupsData: [],
    consoleGroupUsersData: [],
    consoleGroupPermissionsData: [],
    connection_status_image: '',
    publish_rate_image: '',
    mqtt_action_rate_image: '',
    api_request_rate_image: '',
    iotcore_publish_status_image: '',
    alarm: [],
    billboardData: {},
    HostUser: null,
    appInfo: null,
    appUrl: null,
  },
  getters: {
    getHostUserData: (state) => state.HostUser,
    getUserData: (state) => state.userData,
    getStatisRegUserData: (state) => state.statisRegUserData,
    getStatisRegDeviceData: (state) => state.statisRegDeviceData,
    getStatisFwVersionData: (state) => state.statisFwVersionData,
    getStatisActDeviceData: (state) => state.statisActDeviceData,
    getStatisActUserData: (state) => state.statisActUserData,
    getStatisPastActDeviceData: (state) => state.statisPastActDeviceData,
    getStatisPastActUserData: (state) => state.statisPastActUserData,
    getDeviceData: (state) => state.deviceData,
    getDeviceShadowData: (state) => {
      const tmpAry = {};
      state.deviceShadowData.forEach((item) => {
        tmpAry[item.Name] = item.Payload;
      });
      return tmpAry;
    },
    getMobileData: (state) => state.mobileData,
    getSubuserData: (state) => state.subuserData,
    getRegionData: (state) => state.regionData,
    getGroupData: (state) => state.groupData,
    getMessageTemplateData: (state) => state.messageTemplateData,
    getFirmwareIdsData: (state) => state.firmwareIdsData,
    getFirmwareObjectListData: (state) => state.firmwareObjectListData,
    isLoading: (state) => state.isLoading,
    getFirmwarePresignedurlData: (state) => state.firmwarePresignedurlData,
    getMqttCredentialsData: (state) => state.mqttCredentialsData,
    getUserPermissionsData: (state) => state.userPermissionsData,
    getPermissionsData: (state) => state.permissionsData,
    getConsoleUsersData: (state) => {
      let data = state.consoleUsersData;
      for (let item of data) {
        item['UserCreateDate'] = moment(item['UserCreateDate']).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        item['UserLastModifiedDate'] = moment(
          item['UserLastModifiedDate']
        ).format('YYYY-MM-DD HH:mm:ss');
      }
      return data;
    },
    getConsoleUserData: (state) => {
      let data = state.consoleUserData;
      // console.log(data)
      if (data !== null) {
        if (data['UserCreateDate']) {
          data['UserCreateDate'] = moment(data['UserCreateDate']).format(
            'YYYY-MM-DD HH:mm:ss'
          );
        }
        if (data['UserLastModifiedDate']) {
          data['UserLastModifiedDate'] = moment(
            data['UserLastModifiedDate']
          ).format('YYYY-MM-DD HH:mm:ss');
        }
      }
      return data;
    },
    getConsoleUserGroupsData: (state) => state.consoleUserGroupsData,
    getConsoleGroupsData: (state) => state.consoleGroupsData,
    getConsoleGroupUsersData: (state) => {
      let data = state.consoleGroupUsersData;
      for (let item of data) {
        item['UserCreateDate'] = moment(item['UserCreateDate']).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        item['UserLastModifiedDate'] = moment(
          item['UserLastModifiedDate']
        ).format('YYYY-MM-DD HH:mm:ss');
      }
      return data;
    },
    getConsoleGroupPermissionsData: (state) =>
      state.consoleGroupPermissionsData,
    getRuleEngineSqlQAData: (state) => state.ruleEngineSqlQAData,
    connection_status_image: (state) => state.connection_status_image,
    publish_rate_image: (state) => state.publish_rate_image,
    mqtt_action_rate_image: (state) => state.mqtt_action_rate_image,
    api_request_rate_image: (state) => state.api_request_rate_image,
    iotcore_publish_status_image: (state) => state.iotcore_publish_status_image,
    alarm: (state) => state.alarm,
    getBillboardData: (state) => state.billboardData,
    appInfo: (state) => state.appInfo,
    appUrl: (state) => state.appUrl,
  },
  mutations: {
    [types.SET_LOADING](state, val) {
      state.isLoading = val;
    },
    [types.SET_LAST_PROMISE](state, val) {
      state.lastPromise = val;
    },
    [types.SET_USER_DATA](state, val) {
      if (!state.userData && val) {
        state.userData = val;
      } else if (!val) {
        state.userData = val;
      } else {
        const tmp = JSON.parse(JSON.stringify(state.userData));
        const data = state.userData.UserInfoList.concat(val.UserInfoList);
        console.log(data);
        tmp.NextToken = val.NextToken;
        tmp.UserInfoList = state.userData.UserInfoList.concat(val.UserInfoList);
        state.userData = tmp;
      }
    },
    [types.UPDATE_USER_DATA](state, val) {
      console.log(state.userData);
      console.log(val);
      const index = state.userData?.UserInfoList?.findIndex(
        (item) => item.Email === val.Email
      );
      if (index !== -1) {
        state.userData.UserInfoList[index].EnableQAMode = val.QAMode;
        state.userData.UserInfoList[index].APIEndpoint = val.APIEndpoint;
      }
    },
    [types.SET_HOST_USER](state, val) {
      state.HostUser = val;
    },
    [types.SET_STATIS_REG_USER_DATA_INIT](state, val) {
      state.statisRegUserData = val;
    },
    [types.SET_STATIS_REG_USER_DATA](state, val) {
      state.statisRegUserData = [...state.statisRegUserData, ...val];
    },
    [types.SET_STATIS_REG_DEVICE_DATA_INIT](state, val) {
      state.statisRegDeviceData = val;
    },
    [types.SET_STATIS_REG_DEVICE_DATA](state, val) {
      state.statisRegDeviceData = [...state.statisRegDeviceData, ...val];
    },
    [types.SET_STATIS_FW_VERSION_DATA_INIT](state, val) {
      state.statisFwVersionData = val;
    },
    [types.SET_STATIS_FW_VERSION_DATA](state, val) {
      state.statisFwVersionData = [...state.statisFwVersionData, ...val];
    },
    [types.SET_STATIS_ACT_DEVICE_DATA_INIT](state, val) {
      state.statisActDeviceData = val;
    },
    [types.SET_STATIS_ACT_DEVICE_DATA](state, val) {
      state.statisActDeviceData = [...state.statisActDeviceData, ...val];
    },
    [types.SET_STATIS_ACT_USER_DATA_INIT](state, val) {
      state.statisActUserData = val;
    },
    [types.SET_STATIS_ACT_USER_DATA](state, val) {
      state.statisActUserData = [...state.statisActUserData, ...val];
    },
    [types.SET_STATIS_PAST_ACT_USER_DATA_INIT](state, val) {
      state.statisPastActUserData = val;
    },
    [types.SET_STATIS_PAST_ACT_USER_DATA](state, val) {
      state.statisPastActUserData = [...state.statisPastActUserData, ...val];
    },
    [types.SET_STATIS_PAST_ACT_DEVICE_DATA_INIT](state, val) {
      state.statisPastActDeviceData = val;
    },
    [types.SET_STATIS_PAST_ACT_DEVICE_DATA](state, val) {
      state.statisPastActDeviceData = [
        ...state.statisPastActDeviceData,
        ...val,
      ];
    },
    [types.SET_DEVICE_DATA](state, val) {
      state.deviceData = val;
    },
    [types.SET_DEVICE_SHADOW_DATA](state, val) {
      state.deviceShadowData = val;
    },
    [types.SET_MOBILE_DATA](state, val) {
      state.mobileData = val;
    },
    [types.SET_SUBUSER_DATA](state, val) {
      state.subuserData = val;
    },
    [types.SET_REGION_DATA](state, val) {
      state.regionData = val;
    },
    [types.SET_GROUP_DATA](state, val) {
      state.groupData = val;
    },
    [types.SET_MESSAGE_TEMPLATE_DATA](state, val) {
      state.messageTemplateData = val;
    },
    [types.SET_FIRMWARE_IDS_DATA](state, val) {
      state.firmwareIdsData = val;
    },
    [types.SET_FIRMWARE_OBJECT_LIST_DATA](state, val) {
      state.firmwareObjectListData = val;
    },
    [types.SET_FIRMWARE_PRESIGNEDURL_DATA](state, val) {
      state.firmwarePresignedurlData = val;
    },
    [types.SET_MQTT_CREDENTIALS_DATA](state, val) {
      state.mqttCredentialsData = val;
    },
    [types.SET_USER_PERMISSIONS_DATA](state, val) {
      let perms = {};
      val.forEach((element) => {
        for (const key of Object.keys(element)) {
          let arr = perms[key] || [];
          for (const perm of element[key]) {
            arr.push(perm['key']);
          }
          perms = { ...perms, ...{ [key]: arr } };
        }
      });
      state.userPermissionsData = perms;
    },
    [types.SET_CONSOLE_USERS_DATA](state, val) {
      state.consoleUsersData = val;
    },
    [types.SET_CONSOLE_USER_DATA](state, val) {
      state.consoleUserData = val;
    },
    [types.SET_CONSOLE_USER_GROUPS_DATA](state, val) {
      state.consoleUserGroupsData = val;
    },
    [types.SET_CONSOLE_GROUPS_DATA](state, val) {
      state.consoleGroupsData = val;
    },
    [types.SET_CONSOLE_GROUP_USERS_DATA](state, val) {
      state.consoleGroupUsersData = val;
    },
    [types.SET_CONSOLE_GROUP_PERMISSIONS_DATA](state, val) {
      state.consoleGroupPermissionsData = val;
    },
    [types.SET_PERMISSIONS_DATA](state, val) {
      state.permissionsData = val;
    },
    [types.SET_RULE_ENGINE_SQL_QA_DATA](state, val) {
      state.ruleEngineSqlQAData = val;
    },
    [types.SET_CONNECTION_STATUS_IMAGE](state, val) {
      state.connection_status_image = val;
    },
    [types.SET_PUBLISH_RATE_IMAGE](state, val) {
      state.publish_rate_image = val;
    },
    [types.SET_MQTT_ACTION_RATE_IMAGE](state, val) {
      state.mqtt_action_rate_image = val;
    },
    [types.SET_API_REQUEST_RATE_IMAGE](state, val) {
      state.api_request_rate_image = val;
    },
    [types.SET_IOTCORE_PUBLISH_STATUS_IMAGE](state, val) {
      state.iotcore_publish_status_image = val;
    },
    [types.SET_AlARM](state, val) {
      state.alarm = val;
    },
    [types.SET_BILLBOARD_DATA](state, val) {
      if (
        Object.keys(state.billboardData).length === 0 ||
        Object.keys(val).length === 0
      ) {
        state.billboardData = val;
      } else {
        if (state.billboardData?.Billboards) {
          state.billboardData.Billboards = [
            ...state.billboardData.Billboards,
            ...val.Billboards,
          ];
        }
        if (state.billboardData.NextToken) {
          state.billboardData.NextToken = val.NextToken;
        }
      }
    },
    [types.ADD_BILLBOARD_DATA](state, val) {
      state.billboardData.Billboards.unshift(val);
    },
    [types.UPDATE_BILLBOARD_DATA](state, val) {
      const index = state.billboardData.Billboards.findIndex(
        (b) => b.MessageID === val.MessageID
      );
      if (index !== -1) {
        state.billboardData.Billboards.splice(index, 1, val);
      }
    },
    [types.SET_APP_INFO](state, val) {
      state.appInfo = val;
    },
    [types.UPDATE_APP_INFO](state, val) {
      const index = state.appInfo?.findIndex(
        (app) => app.Platform === val.Platform && app.Type === val.Type
      );
      if (index !== -1) {
        state.appInfo[index] = val;
      }
    },
    [types.SET_APP_URL](state, val) {
      state.appUrl = val;
    },
    [types.UPDATE_APP_URL](state, val) {
      const index = state.appUrl?.findIndex((app) => app.Type === val.Type);
      if (index !== -1) {
        state.appUrl[index] = val;
      }
    },
  },
  actions: {
    async loading({ commit }, flag) {
      commit(types.SET_LOADING, !!flag);
    },
    async searchUser({ commit }, { email, next_token }) {
      try {
        commit(types.SET_LOADING, true);
        let path = `/users/info?email=${encodeURIComponent(email)}`;
        if (next_token) {
          path += `&next_token=${encodeURIComponent(next_token)}`;
        }
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const userData = await promise;
        commit(types.SET_USER_DATA, userData);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async switchQAMode(
      { dispatch, commit },
      { Email, EnableQAMode, ApiEndpoint }
    ) {
      try {
        commit(types.SET_LOADING, true);

        let reqInit = {
          body: {
            Email: Email,
            QAMode: EnableQAMode === 'true',
          },
        };
        if (ApiEndpoint) {
          reqInit.body['APIEndpoint'] = ApiEndpoint;
        }
        const path = `/users/debug`;
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        const result = await promise;
        console.log(result);
        // await dispatch('searchUser', { email: Email });
        commit(types.UPDATE_USER_DATA, result);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        if (API.isCancel(error)) {
          commit(types.SET_LAST_PROMISE, null);
          commit(types.SET_LOADING, false);
        }
      }
    },
    async getHostUser({ commit }, userId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/hostuser?user_id=${encodeURIComponent(userId)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_HOST_USER, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisRegUser(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/reg/user?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        // if (initialization) {
        commit(types.SET_STATIS_REG_USER_DATA_INIT, data);
        // } else {
        //   commit(types.SET_STATIS_REG_USER_DATA, data)
        // }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisRegDevice(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/reg/device?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        if (initialization) {
          commit(types.SET_STATIS_REG_DEVICE_DATA_INIT, data);
        } else {
          commit(types.SET_STATIS_REG_DEVICE_DATA, data);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisFwVersion(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/fw/version?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        if (initialization) {
          commit(types.SET_STATIS_FW_VERSION_DATA_INIT, data);
        } else {
          commit(types.SET_STATIS_FW_VERSION_DATA, data);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisActDevice(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/act/device?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        if (initialization) {
          commit(types.SET_STATIS_ACT_DEVICE_DATA_INIT, data);
        } else {
          commit(types.SET_STATIS_ACT_DEVICE_DATA, data);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisActUser(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/act/user?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        if (initialization) {
          commit(types.SET_STATIS_ACT_USER_DATA_INIT, data);
        } else {
          commit(types.SET_STATIS_ACT_USER_DATA, data);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisPastActUser(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/past_act/user?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        if (initialization) {
          commit(types.SET_STATIS_PAST_ACT_USER_DATA_INIT, data);
        } else {
          commit(types.SET_STATIS_PAST_ACT_USER_DATA, data);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getStatisPastActDevice(
      { commit },
      { firstDayOfMonth, initialization = false }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/statis/past_act/device?first_day_of_month=${encodeURIComponent(
          firstDayOfMonth
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        if (initialization) {
          commit(types.SET_STATIS_PAST_ACT_DEVICE_DATA_INIT, data);
        } else {
          commit(types.SET_STATIS_PAST_ACT_DEVICE_DATA, data);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    cancelPromise({ state, commit }) {
      API.cancel(state.lastPromise, 'API call cancellation');
      commit(types.SET_LAST_PROMISE, null);
      commit(types.SET_LOADING, false);
    },
    async getDevice({ commit }, userId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/device?user_id=${encodeURIComponent(userId)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_DEVICE_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getDeviceShadow({ commit }, thingName) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/device/shadow?thing_name=${encodeURIComponent(
          thingName
        )}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_DEVICE_SHADOW_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getMobile({ commit }, userId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/mobile?user_id=${encodeURIComponent(userId)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_MOBILE_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getSubuser({ commit }, userId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/subuser?user_id=${encodeURIComponent(userId)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_SUBUSER_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getRegion({ commit }, userId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/region?user_id=${encodeURIComponent(userId)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_REGION_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getGroup({ commit }, userId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/users/group?user_id=${encodeURIComponent(userId)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_GROUP_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getMessageTemplate({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/message/items`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_MESSAGE_TEMPLATE_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getFirmwareIds({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/firmware_release/firmwareids`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_FIRMWARE_IDS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async listFirmwareObjects({ commit }, firmwareId) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/firmware_release/firmware/${firmwareId}/objects`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_FIRMWARE_OBJECT_LIST_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async releaseFirmware(
      { dispatch, commit },
      { firmwareId, releasedEnv, firmwareFileName }
    ) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/firmware_release/firmware/${firmwareId}/release`;
        let reqInit = {
          body: {
            ReleasedEnv: releasedEnv,
            FirmwareFileName: firmwareFileName,
          },
        };
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        await dispatch('listFirmwareObjects', firmwareId);
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async updateRuleEngine({ commit }, sql) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/firmware_release/update_rule_engine_qa`;
        let reqInit = {
          body: {
            SQL: window.btoa(sql),
          },
        };
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        alert('Success');
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async getRuleEngineSqlQA({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/firmware_release/get_rule_engine_sql_qa`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_RULE_ENGINE_SQL_QA_DATA, window.atob(data.SQL));
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async getPresignUrl({ commit }, { fileName, firmwareId, contentMD5 }) {
      try {
        const path = `/firmware_release/presignedurl`;
        let reqInit = {
          body: {
            FileName: fileName,
            FirmwareId: firmwareId,
            ContentMD5: contentMD5,
          },
        };
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_FIRMWARE_PRESIGNEDURL_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
      } catch (error) {
        commit(types.SET_FIRMWARE_PRESIGNEDURL_DATA, null);
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
      }
    },

    async createMessageTemplate({ dispatch, commit }, { formData }) {
      try {
        commit(types.SET_LOADING, true);
        let reqInit = {
          body: {
            CustomerID: formData.CustomerID,
            MessageType: formData.MessageType,
            MessageKey: formData.MessageKey,
            en_US: formData.en_US,
            es_ES: formData.es_ES,
            zh_CN: formData.zh_CN,
            de_DE: formData.de_DE,
            it_IT: formData.it_IT,
            zh_TW: formData.zh_TW,
          },
        };
        const path = `/message/item`;
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        await dispatch('getMessageTemplate');
        alert('Success');
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async updateMessageTemplate({ dispatch, commit }, { formData }) {
      try {
        commit(types.SET_LOADING, true);
        let reqInit = {
          body: {
            CustomerID: formData.CustomerID,
            MessageType: formData.MessageType,
            MessageKey: formData.MessageKey,
            en_US: formData.en_US,
            es_ES: formData.es_ES,
            zh_CN: formData.zh_CN,
            de_DE: formData.de_DE,
            it_IT: formData.it_IT,
            zh_TW: formData.zh_TW,
          },
        };
        const path = `/message/item`;
        const promise = API.put('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        await dispatch('getMessageTemplate');
        alert('Success');
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async removeMessageTemplate(
      { dispatch, commit },
      { customerID, messageType, messageKey }
    ) {
      try {
        commit(types.SET_LOADING, true);
        let reqInit = {
          body: {
            CustomerID: customerID,
            MessageType: messageType,
            MessageKey: messageKey,
          },
        };
        const path = `/message/item`;
        const promise = API.del('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        await dispatch('getMessageTemplate');
        alert('Success');
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async getMqttCredentials({ commit }) {
      try {
        const path = `/auth/mqtt/credentials`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_MQTT_CREDENTIALS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getUserPermissions({ commit }) {
      try {
        const path = `/console/user/permissions`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_USER_PERMISSIONS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getConsoleUsers({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/users`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONSOLE_USERS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getConsoleUser({ commit }, email) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/user/${encodeURIComponent(email)}`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONSOLE_USER_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getConsoleUserGroups({ commit }, email) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/user/${encodeURIComponent(email)}/groups`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONSOLE_USER_GROUPS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getConsoleGroups({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/groups`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONSOLE_GROUPS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async addUserToGroups({ commit }, { username, groupName }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/group/user`;
        let reqInit = {
          body: {
            Username: username,
            GroupName: groupName,
          },
        };
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async removeUserFromGroup({ commit }, { username, groupName }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/group/user`;
        let reqInit = {
          body: {
            Username: username,
            GroupName: groupName,
          },
        };
        const promise = API.del('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async createGroup({ commit }, { groupName, description }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/group`;
        let reqInit = {
          body: {
            GroupName: groupName,
            Description: description,
          },
        };
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        alert('Success');
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getConsoleGroupUsers({ commit }, groupName) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/group/${encodeURIComponent(groupName)}/users`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONSOLE_GROUP_USERS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getConsoleGroupPeremissions({ commit }, groupName) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/group/${encodeURIComponent(
          groupName
        )}/permissions`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONSOLE_GROUP_PERMISSIONS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getPermissions({ commit }) {
      try {
        const path = `/console/permissions`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_PERMISSIONS_DATA, data);
        commit(types.SET_LAST_PROMISE, null);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async updateGroupPermissions({ commit }, { groupName, permissions }) {
      try {
        commit(types.SET_LOADING, true);
        let reqInit = {
          body: permissions,
        };
        const path = `/console/group/${encodeURIComponent(
          groupName
        )}/permissions`;
        const promise = API.put('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        alert('Success');
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      }
    },
    async removeGroup({ commit }, groupName) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/console/group`;
        let reqInit = {
          body: {
            GroupName: groupName,
          },
        };
        const promise = API.del('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async createAdminUser({ dispatch, commit }, { formData }) {
      try {
        const path = `/console/user`;
        let reqInit = {
          body: {
            Username: formData.Name,
            Email: formData.Email,
            Password: formData.Password,
          },
        };
        const promise = API.post('APIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        await promise;
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        await dispatch('getConsoleUsers');
        alert('Success');
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
      }
    },
    async getConnectionStatus({ commit }) {
      try {
        const path = `/metric/iotcore_connection_status`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_CONNECTION_STATUS_IMAGE, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getPublishRate({ commit }) {
      try {
        const path = `/metric/publish_rate`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_PUBLISH_RATE_IMAGE, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getMqttActionRate({ commit }) {
      try {
        const path = `/metric/mqtt_action_rate`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_MQTT_ACTION_RATE_IMAGE, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getApiRequestRate({ commit }) {
      try {
        const path = `/metric/api_request_rate`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_API_REQUEST_RATE_IMAGE, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getIotcorePublishStatus({ commit }) {
      try {
        const path = `/metric/iotcore_publish_status`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_IOTCORE_PUBLISH_STATUS_IMAGE, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getAlarm({ commit }) {
      try {
        const path = `/data_usage/alarm`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_AlARM, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    /** IoT API **/
    async getBillboardSettings({ commit }, { category, nextToken = null }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/GetBillboardSettings`;
        let reqInit = {
          body: {
            Category: enums.getBillboardType(category),
            NextToken: nextToken,
          },
        };
        const promise = API.post('IoTAPIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        const response = await promise;
        if (response.status.code !== 0) {
          throw 'response status code: ' + response.status.code;
        }
        commit(types.SET_BILLBOARD_DATA, response.results);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
      }
    },
    /** IoT API **/
    async PutBillboardSetting({ commit }, data) {
      try {
        const path = `/PutBillboardSetting`;
        const reqInit = {
          body: data,
        };
        const promise = API.post('IoTAPIGateway', path, reqInit);
        commit(types.SET_LAST_PROMISE, promise);
        const response = await promise;
        if (response.status.code !== 0) {
          throw 'response status code: ' + response.status.code;
        }
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (data.MessageID) {
          commit(types.UPDATE_BILLBOARD_DATA, response.results);
        } else {
          const currentRouteName = router.currentRoute.value.params.category;
          if (currentRouteName === enums.getBillboardType(data.Category)) {
            commit(types.ADD_BILLBOARD_DATA, response.results);
          } else {
            router.push('/billboard/' + enums.getBillboardType(data.Category));
          }
        }
      } catch (error) {
        if (!API.isCancel(error)) {
          let responseContent = '';
          if (error.response !== undefined) {
            responseContent = '\n' + JSON.stringify(error.response.data);
          }
          alert('Error: ' + error.message + responseContent);
        }
        commit(types.SET_LAST_PROMISE, null);
      }
    },
    async getAppInfo({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/app/info`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_APP_INFO, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async updateAppInfo({ commit }, payload) {
      try {
        const params = {
          body: payload,
        };
        commit(types.SET_LOADING, true);
        const path = `/app/info`;
        const promise = API.put('APIGateway', path, params);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.UPDATE_APP_INFO, payload);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async getAppUrl({ commit }) {
      try {
        commit(types.SET_LOADING, true);
        const path = `/app/url`;
        const promise = API.get('APIGateway', path);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.SET_APP_URL, data);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
    async updateAppUrl({ commit }, payload) {
      try {
        const params = {
          body: payload,
        };
        commit(types.SET_LOADING, true);
        const path = `/app/url`;
        const promise = API.put('APIGateway', path, params);
        commit(types.SET_LAST_PROMISE, promise);
        const data = await promise;
        commit(types.UPDATE_APP_URL, payload);
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        return data;
      } catch (error) {
        commit(types.SET_LAST_PROMISE, null);
        commit(types.SET_LOADING, false);
        if (API.isCancel(error)) {
          console.log(error.message);
        } else {
          alert(error);
        }
      }
    },
  },
});
