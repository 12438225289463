const BillboardTypes = {
  activity: 'MSG_ACTIVITY',
  personal: 'MSG_PERSONAL',
  important: 'MSG_IMPORTANT',
  MSG_ACTIVITY: 'activity',
  MSG_PERSONAL: 'personal',
  MSG_IMPORTANT: 'important',
};

export default {
  getBillboardType(type) {
    return BillboardTypes[type] || BillboardTypes.activity;
  },
};
